// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/css/EventDialog.scss

@import '~primeflex/primeflex.scss'; // If using PrimeFlex for layout

// Assuming the import statement is fixed or removed
.bigButton {
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  

  display: inline-flex; 
  .p-button-label {
    flex-grow: 1;
    
  }
  .p-button-icon-left {
    margin-right: 0.5rem;
    
  }
}

// You can also ensure the entire button column is centered
.centeredButtonCol {
  display: flex;
  justify-content: center;
  align-items: center;
}

