/* MessagesPanel.css */

.messages-panel {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
  }
  
  .message-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
  }
  
  .message-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ddd;
    margin-right: 15px;
  }
  
  .message-content {
    flex-grow: 1;
  }
  
  .message-timestamp {
    color: #999;
    font-size: 0.85rem;
  }
  