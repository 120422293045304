.node-container {
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 120px;
    overflow: auto;
    margin: 10px;  // Add margin for spacing
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  // Add a subtle shadow for better visuals
}

.spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.zoom-controls {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.mlm-chart .p-organizationchart-node-content {
    margin: 5px;  // Adjust margin for better spacing between nodes
}




