/* DataGraph.css */

.data-graph-container {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
  }
  
  /* This style is to ensure the chart fills the container properly */
  .chart-container {
    height: 300px;
    width: 100%;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .data-graph-container {
      grid-template-columns: 1fr;
    }
  }
  