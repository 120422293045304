// /var/www/website2024/192.testproject.work/website2024/s-303-happydebtclinic/admin/src/css/MemberTable.scss
// MemberTable.scss

// Import the OrgPositionsColor.json file

.data-table {
  .p-datatable-wrapper {
    overflow-x: auto;

    .p-datatable-tbody>tr>td {
      padding: 4px 8px; // Adjust the padding as necessary
    }

    .p-column-title {
      font-size: 0.9rem; // Adjust font size as necessary
    }

    .p-datatable-thead>tr>th {
      padding: 4px 8px; // Adjust the padding as necessary
    }

    .p-column-header-content {
      white-space: nowrap; // Prevent header text from wrapping
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .p-column-content {
      white-space: nowrap; // Prevent cell text from wrapping
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .custom-column-short {
      min-width: 3rem; // Adjust the minimum width of the custom column
    }

    .custom-column {
      min-width: 8rem; // Adjust the minimum width of the custom column
    }

    .custom-column-upline {
      min-width: 10rem; // Adjust the minimum width of the custom column
    }

    .custom-column-contact {
      min-width: 15rem; // Adjust the minimum width of the custom column
    }


  }
}

.data-table .p-datatable-tbody>tr:hover {
  background-image: linear-gradient(to right, #6fffc5, #95f1ff, #57f2ed, #6effc4);
}

.data-table .backgroundColor {
  background-color: #fffef2;
}

.data-table {

  // Define background colors for positions
  .position-customer {
    background-color: #fff5eb;
  }

  .position-employee {
    background-color: #C2C2F0;
  }

  .position-admin {
    background-color: #ffe6ec;
  }

  .position-ceo {
    background-color: #abf2ff;
  }

  .position-sales-manager {
    background-color: #fff6f2;
  }

  .position-sales-representative {
    background-color: #ffe5da;
  }

  
}