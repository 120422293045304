// File: styles/Login.module.scss

.login-background {
  height: 100vh; // Viewport Height: make the element as tall as the viewport
  background-image: url('https://afaa.website/s/1f2ab9.webp'); // Replace with your image URL
  background-position: center;
  background-size: cover; // Cover ensures the background image fully covers the area
  background-repeat: no-repeat;
  position: relative; // Required for the positioning of the pseudo-element

  /* Overlay with Blur Effect */
  &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(5px); // Apply blur effect
      background-color: rgb(237 156 199 / 14%);
  }
}


  
  .card-container {
    max-width: 400px;
    margin: auto; // Center the card horizontally and vertically
    position: relative;
    top: 50%; // Push down from the top by 50% of its parent height
    transform: translateY(-50%); // Translate up by 50% of its own height to achieve central alignment
  }
  
  .card-login {
    background-color: rgb(255 255 255 / 80%) !important;
  }

  .font13{
    font-size: 13px;
  }