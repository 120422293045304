.search-section {
  position: relative;
}
  
  .search-input {
    width: 100%;
    margin-bottom: 0;
  }

  .overlay-panel-custom {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    z-index: 1000;
  }


.overlayPanelCustom {
  position: absolute;
  top: 25% !important;
  right: 45% !important;
  left: 10% !important;
}
