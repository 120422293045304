// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/css/DataQueues.scss

.fc-event {
    border-color: #17b917 !important;
    background-image: linear-gradient(to right, #fad3c3, #edc4c7, #a7edf7);
}

.fc-v-event .fc-event-main {
    color: #333;
    font-size: 15px;
}




.fc-header-toolbar {
    position: relative;
  }
  
  .custom-switch-container {
    position: absolute;
    top: 110px; /* Adjust based on your FullCalendar header height */
    right: 10px; /* Place it towards the right */
    display: flex;
    align-items: center;
  }
  
  .custom-switch-label {
    margin-left: 0.5em; /* Space between switch and label */
  }