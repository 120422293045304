/* /var/www/website2023/py.testproject.work/s-293-spatime-admin/src/css/MainContent.css */

.main-content {
  flex-grow: 1;
  padding: 20px;
  background: #f0f4f8; /* Soft background */
  overflow-y: auto; /* Scrollable content */
}

.stats-section {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}


.card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.609);
  padding: 20px;
  margin-bottom: 20px;
  /* Transition for hover effects */
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.card:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.659);
  transform: translateY(-5px);
}
