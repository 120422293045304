/* Member.css */
.member-table-container {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* สร้างเงาจางๆ */
    border-radius: 8px; /* สร้างมุมโค้งมน */
    overflow: hidden; /* ป้องกันไม่ให้ตารางล้นขอบมุมโค้งมน */
    margin: 0px 20px 20px 20px;
    background: white; /* กำหนดพื้นหลังสีขาว (ไม่บังคับ) */
    padding: 0px 10px 10px 10px;
}
  
  .table-responsive {
    overflow-x: auto; /* อนุญาตให้ตารางเลื่อนในแนวนอนบนหน้าจอขนาดเล็ก */
  }
  
  .table {
    width: 100%; /* กำหนดให้ตารางใช้พื้นที่เต็มความกว้างของคอนเทนเนอร์ */
    margin-bottom: 0; /* ลบระยะห่างด้านล่างที่เป็นค่าเริ่มต้น */
  }

  /* Member.css */
  .header-title {
    margin-bottom: 20px; /* adjust as needed */
  }
  
  .add-member-icon {
    display: inline-block;
    width: 30px; /* Or the size you want */
    height: 30px; /* Or the size you want */
    border-radius: 50%; /* Makes it circular */
    background-color: #4CAF50; /* Or any color you prefer */
    color: white; /* Icon color */
    text-align: center; /* Center the icon/text */
    line-height: 30px; /* Align the text vertically */
    cursor: pointer; /* Change cursor on hover */
    margin-bottom: 10px; /* Space from the table or adjust as needed */
  }
  
  /* If using a character, adjust font size as needed */
  .plus-icon {
    font-size: 24px; /* Adjust size as needed */
    font-weight: bold;
  }
.pa-top-10{
  padding-top: 10px;
}
  /* เพิ่มสไตล์อื่นๆ เพิ่มเติมตามต้องการ เช่น ระยะห่างภายใน (padding) เป็นต้น */

  